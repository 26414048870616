import $http from './http.js'
// 附件下载地址
export const downloadPath = '/sys/accessory/download/'
export function getFileUrl (id) {
    if (id) {
        return $http.BASE_URL + window.downloadPath + id
    } else {
        return ''
    }
}
window.downloadPath = downloadPath
export default {
    downloadPath,
    getFileUrl
}
