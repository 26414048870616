<template>
    <div style="height: 60px;line-height: 60px;background: #000000;font-size: 16px;color: #FFFFFF;text-align: center;">
        备案号：<a href="https://beian.miit.gov.cn">宁ICP备2022000399号-1</a>
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Footer'
    }
</script>

<style scoped>

</style>
