import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
// import store from '@/store'
import qs from 'qs'
import {
    Message,
    Loading
} from 'element-ui'

// 超时时间
axios.defaults.timeout = 60000
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// axios.defaults.headers = {'Content-Type': 'application/json; charset=utf-8'}
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
// 非生产环境 && 开启代理, 接口前缀统一使用[/api]前缀做代理拦截!
const BASE_URL = process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_PROXY_API_PATH
    : (process.env.VUE_APP_SERVER_URL ? process.env.VUE_APP_SERVER_URL : location.protocol + '//' + location.host + (process.env.VUE_APP_SERVER_CONTEXT || ''))
// 对面暴露的基础请求路径
axios.BASE_URL = BASE_URL

/**
 * 请求拦截
 */
let loading
let requestIndex = 0
axios.interceptors.request.use(config => {
    let showLoading = false
    if (config.loading === true) {
        showLoading = true
    }
    if (showLoading) {
        loading = Loading.service({
            text: config.loadingText || '正在请求...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
    }
    // 非登录请求, 请求头带上token
    if (config.url.indexOf('/sys/login') === -1) {
        // store.commit('common/cleanAxiosPromises')
        config.headers.token = sessionStorage.getItem('token')
    }
    // 请求地址处理
    config.url = BASE_URL + config.url
    const type = config.method
    const arrayFormat = config.headers.arrayFormat || 'indices'
    if (type === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=utf-8') {
        // post请求参数处理
        config.data = qs.stringify(config.data, {
            allowDots: true,
            arrayFormat: arrayFormat
        })
    } else if (type === 'get') {
        // get请求参数处理
        config.params = qs.stringify(config.params, {
            allowDots: true,
            arrayFormat: arrayFormat
        })
        config.params = qs.parse(config.params)
    }
    // axios cancel token, 用于退出登录时取消所有未完成的请求
    config.cancelToken = new axios.CancelToken(cancel => {
        try {
            // store.commit('common/pushAxiosPromises', requestIndex, cancel)
        } catch (e) {
            console.error(e)
        }
    })
    config.cancelToken.requestIndex = requestIndex++
    return config
}, error => {
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
let loginTimeoutMsg = false
axios.interceptors.response.use(response => {
    if (loading) {
        loading.close()
    }
    if (response.data && response.data.success === false) {
        Message({
            message: response.data.msg,
            type: 'error',
            showClose: true,
            dangerouslyUseHTMLString: true,
            duration: 3000,
            customClass: 'zZindex'
        })
    }
    return response
}, error => {
    if (loading) {
        loading.close()
    }
    // 正常响应, 有响应状态码
    if (error.response && error.response.status) {
        // 无访问权限
        if (error.response.status === 401) {
            Message({
                message: error.response.data.msg,
                type: 'error',
                showClose: true,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                customClass: 'zZindex'
            })
        } else if (error.response.status === 403) { // 路径找不到
            const token = Vue.cookie.get('token')
            if (token) {
                if (doRefreshToken()) {
                    return doRetryRequest(error.response.config)
                } else {
                    router.push({ name: 'login' })
                }
            } else {
                if (!loginTimeoutMsg) {
                    loginTimeoutMsg = true
                    Message({
                        message: '请重新登录!',
                        type: 'error',
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        duration: 3000,
                        customClass: 'zZindex'
                    })
                    setTimeout(function () {
                        loginTimeoutMsg = false
                    }, 10000)
                }
                router.push({ name: 'login' })
            }
        } else if (error.response.status === 404) { // 路径找不到
            Message({
                message: '找不到指定文件！',
                type: 'warning',
                showClose: true,
                duration: 3000
            })
        } else if (error.response.status === 504) {
            Message({
                message: '网络连接错误' + ':' + error.response.data,
                type: 'error',
                showClose: true,
                duration: 3000,
                customClass: 'zZindex'
            })
        } else {
            Message({
                message: error.response.data.msg || error.response.data.exception || error.response.data || error.response || error,
                type: 'error',
                showClose: true,
                duration: 5000,
                customClass: 'zZindex'
            })
        }
    } else {
        // console.error(error.response.message)
    }
    return Promise.reject(error)
})

let refreshTokenLock = false

async function doRefreshToken () {
    if (!refreshTokenLock) {
        refreshTokenLock = true
        await axios({
            url: '/sys/refreshToken',
            method: 'get',
            params: { token: Vue.cookie.get('token') }
        }).then(({ data }) => {
            refreshTokenLock = false
            if (data && data.success) {
                Vue.cookie.set('token', data.token)
                sessionStorage.setItem('token', data.token)
                return true
            } else {
                router.push({ name: 'login' })
                return false
            }
        }).catch(() => {
            refreshTokenLock = false
            router.push({ name: 'login' })
            return false
        })
    } else {
        const delay = setInterval(function () {
            if (!refreshTokenLock) {
                clearInterval(delay)
            }
        }, 300)
    }
}

async function doRetryRequest (config) {
    if (process.env.NODE_ENV === 'development') {
        const originalRequestUrl = config.url
        const replacement = new RegExp('^' + process.env.VUE_APP_PROXY_API_PATH)
        config.url = originalRequestUrl.replace(replacement, '')
    }
    const result = await axios.request(config)
    return result
}

export default axios
