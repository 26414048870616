<template>
    <div class="header">
        <div class="login">
            <span class="btn" @click="toLogin">登录</span>
        </div>
        <div class="nav">
            <div class="content">
                <div style="background: #FFFFFF;padding: 0 20px;height: 60px;display: flex;justify-content: center;align-items: center;">
                    <img src="@/assets/img/logo.png" alt="" height="40px">
                    <span style="font-size: 26px;font-weight: 600;color: #0000FE;margin: 0 0 0 10px;">银川电商直播公共服务基地</span>
                </div>
                <!--<img src="../../assets/img/logo.jpg" alt="" height="60px">-->
                <div class="nav-list">
                    <span :class="item.active?'active':''" v-for="item in navList" :key="item.value"
                          @click="navClick(item)">{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Header',
        data () {
            return {
                navList: [
                    {
                        value: '/home',
                        name: '首页',
                        active: true
                    },
                    {
                        value: '/business',
                        name: '商家列表',
                        active: false
                    },
                    {
                        value: '/product',
                        name: '产品列表',
                        active: false
                    },
                    {
                        value: '/talent',
                        name: '达人列表',
                        active: false
                    }
                ]
            }
        },
        watch: {
            $route (to, from) {
                // eslint-disable-next-line array-callback-return
                this.navList.map(i => {
                    i.active = false
                    if (to.path.indexOf(i.value) > -1) {
                        i.active = true
                    }
                })
            }
        },
        methods: {
            navClick (item) {
                // eslint-disable-next-line array-callback-return
                this.navList.map(i => {
                    i.active = false
                    if (i.value === item.value) {
                        i.active = true
                        this.$router.push(item.value)
                    }
                })
            },
            toLogin () {
                window.open('/console')
                // window.open('http://222.75.158.78:8056/console')
            }
        }
    }
</script>

<style scoped lang="scss">
    .header {
        width: 100%;
        background: #ffffff;

        .login {
            width: 1200px;
            margin: 0 auto;
            height: 100px;
            line-height: 100px;
            text-align: right;

            .btn {
                display: inline-block;
                width: 60px;
                height: 46px;
                line-height: 46px;
                background: #000000;
                color: #ffffff;
                font-size: 16px;
                text-align: center;
                border-radius: 30px;
                margin: 27px 0;
                cursor: pointer;
            }
        }

        .nav {
            height: 60px;
            background: #000000;

            .content {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .nav-list {
                    text-align: right;
                    margin: 0 50px 0 0;

                    span {
                        display: inline-block;
                        width: 145px;
                        line-height: 60px;
                        color: #ffffff;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                    }

                    span:hover, span.active {
                        color: #7aff51;
                    }
                }
            }
        }
    }
</style>
