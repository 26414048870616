import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        redirect: { name: 'home' },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('../views/home/index.vue')
            }
        ]
    },
    {
        path: '/business',
        name: 'business',
        component: () => import(/* webpackChunkName: "about" */ '../views/business/index.vue')
    },
    {
        path: '/business/busDetails',
        name: 'busDetails',
        component: () => import('../views/business/Details.vue')
    },
    {
        path: '/product',
        name: 'product',
        redirect: { name: 'products' },
        component: () => import('../views/product/index.vue'),
        children: [
            {
                path: '/product/index',
                name: 'products',
                component: () => import('../views/product/Products.vue')
            },
            {
                path: '/product/details',
                name: 'details',
                component: () => import('../views/product/Details.vue')
            }
        ]
    },
    {
        path: '/talent',
        name: 'talent',
        component: () => import('../views/talent/index.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})

export default router
