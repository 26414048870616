<template>
    <div id="app">
        <Header></Header>
        <router-view/>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from './views/home/Header'
    import Footer from './views/home/Footer'

    export default {
        name: 'App',
        components: {
            Header,
            Footer
        }
    }
</script>
<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        /*-webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;*/
    }
</style>
